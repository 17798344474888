import React from 'react'
import Layout from '../components/layout'
import { Heading } from 'grommet'
import { withIntl } from '../i18n/'
import styles from './partners.module.css'

const mediaPartnersDir = require.context('../assets/media_partners', true)
const goldPartnersDir = require.context('../assets/partners/golden', true)
const premiumPartnersDir = require.context('../assets/partners/premium', true)
const silverPartnersDir = require.context('../assets/partners/silver', true)
const supportersPartnersDir = require.context(
  '../assets/partners/supporters',
  true
)
const transportationPartnersDir = require.context(
  '../assets/partners/transportation',
  true
)
const foodPartnersDir = require.context('../assets/partners/food', true)
const accommodationPartnersDir = require.context(
  '../assets/partners/accommodation',
  true
)
const printingPartnersDir = require.context('../assets/partners/printing', true)

const PremiumPartnersUrls = {
  farcom: 'http://www.farcom.gr/',
  cu: 'https://www.vodafonecu.gr/'
}

const GoldPartnersUrls = {
  honor: 'https://www.hihonor.com/gr/',
  act: 'https://www.act.edu/'
}

const SilverPartnersUrls = {
  elpe: 'https://www.helpe.gr/',
}

const SupportersUrls = {
  make: 'https://www.make.gr/el/',
  maped: 'https://gr.maped.com/el_gr/',
  caravan: 'https://www.thecaravan.gr/',
  maroukian: 'https://maroukianbros.gr/en/',
  felipack: 'https://felipack.gr/',
  baboulas: 'http://www.baboulas.gr/',
  eleftheriou: 'https://www.eleftheriou.net/',
  great_escape: 'https://www.greatescape.gr/',
  apodec: 'https://www.apodec.space',
  mba: 'http://mba.uom.gr/',
  vlasidou: 'http://www.makeupconcepts.gr/'
}

const AccommodationPartnersUrls = {
  mood: 'https://www.themoodthessaloniki.gr/?lang=en',
  bahar: 'https://www.baharboutiquehotel.com'
}

const PremiumMediaPartnersUrls = {
  biscotto: 'https://www.biscotto.gr/',
  mycampus: 'https://www.mycampus.gr/',
  plus: 'http://plusradio.gr/',
}

const MediaPartnersUrls = {
  exposed: 'https://www.helexpo.gr/el/exposed_publication',
  together: 'http://togethermag.gr/',
  shedia: 'http://www.shedia.gr/',
  tv100: 'https://www.fm100.gr/',
  fm100: 'https://www.fm100.gr/',
  beater: 'https://beater.gr/',
  infinity: 'http://infinitygreece.com/',
  infititis: 'http://www.infititis.gr/',
  praktoreio: 'http://www.amna.gr/',
  praktoreio104: 'http://www.praktoreiofm.gr/',
  radionw: 'https://radionw.gr/',
  voria: 'https://www.voria.gr/',
  yang: 'https://www.yang.gr/',
  skywalker: 'https://www.skywalker.gr/',
  makedonia: 'https://www.makthes.gr/'
}

const FoodPartnersUrls = {
  bios: 'http://www.vios5.gr/', 
  vikos_cola: 'https://www.vikoswater.gr/',
  vikos: 'https://www.vikoswater.gr/',
  damaskios: 'https://www.damaskioswinery.gr/',
  jager: 'https://www.jagermeister.com/en/',
  mamos: 'http://www.mamos.gr/',
  mpezes: 'https://www.facebook.com/Mpezes/',
  strongbow: 'https://www.strongbow.com/',
  taf: 'http://www.cafetaf.gr',
  choco_joy: 'https://www.mevgal.gr/frontend/index.php',
  fridays: 'https://www.fridays.gr/',
  elenidis: 'https://www.elenidis.com/',
  juicy_lucy: 'https://www.facebook.com/juicylucyburgerjoint/',
  nterlikatesen: 'https://www.facebook.com/NterlikatesenOlaSoublaki/',
  tsoungari: 'https://www.biscotti-tsoungari.com/en/',
  falafel: 'https://www.facebook.com/falafelhousethess/'
}

const transportationPartnersUrls = {
  ellinair: 'https://el.ellinair.com/',
  mercedes: 'https://mercedes.ioannidis.net.gr/',
}

const printingPartnersUrls = {
  ekdoseis: 'http://afroditi.uom.gr/uompress/',
  mavrogenis: '#'
}

const CategoryList = ({ urls, category, categoryDir }) => {
  const PartnersUrlsArr = Object.entries(urls)
  return (
    <div style={{ padding: '2rem' }}>
      <Heading level="2" style={{ fontWeight: '300' }}>
        {category}
      </Heading>
      <PartnersGrid>
        {PartnersUrlsArr.map(([partnerName, partnerUrl]) => (
          <Partner
            name={partnerName}
            url={partnerUrl}
            key={partnerName}
            dir={categoryDir}
          />
        ))}
      </PartnersGrid>
    </div>
  )
}



const GoldCategoryList = ({ urls, category, categoryDir }) => {
  const PartnersUrlsArr = Object.entries(urls)
  return (
    <div style={{ padding: '2rem' }}>
      <Heading level="2" style={{ fontWeight: '300' }}>
        {category}
      </Heading>
      <PartnersGrid>
        {PartnersUrlsArr.map(([partnerName, partnerUrl]) => (
          <GoldPartner
            name={partnerName}
            url={partnerUrl}
            key={partnerName}
            dir={categoryDir}
          />
        ))}
      </PartnersGrid>
    </div>
  )
}
const PremiumPartners = () => {
  return (
    <CategoryList
      urls={PremiumPartnersUrls}
      category="Premium Partners"
      categoryDir={premiumPartnersDir}
    />
  )
}

const GoldPartners = () => {
  return (
    <GoldCategoryList
      urls={GoldPartnersUrls}
      category="Golden Partners"
      categoryDir={goldPartnersDir}
    />
  )
}


const FoodPartners = () => {
  return (
    <CategoryList
      urls={FoodPartnersUrls}
      category="Food and Drinks"
      categoryDir={foodPartnersDir}
    />
  )
}

const SilverPartners = () => {
  return (
    <CategoryList
      urls={SilverPartnersUrls}
      category="Silver Partners"
      categoryDir={silverPartnersDir}
    />
  )
}

const Supporters = () => {
  return (
    <CategoryList
      urls={SupportersUrls}
      category="Supporters"
      categoryDir={supportersPartnersDir}
    />
  )
}

const AccommodationPartners = () => {
  return (
    <CategoryList
      urls={AccommodationPartnersUrls}
      category="Hospitality Partners"
      categoryDir={accommodationPartnersDir}
    />
  )
}

const MediaPartners = () => {
  return (
    <CategoryList
      urls={MediaPartnersUrls}
      category="Media Partners"
      categoryDir={mediaPartnersDir}
    />
  )
}

const TransportationPartners = () => (
  <CategoryList
    urls={transportationPartnersUrls}
    category="Transporation Partners"
    categoryDir={transportationPartnersDir}
  />
)

const PrintingPartners = () => (
  <CategoryList
    urls={printingPartnersUrls}
    category="Printing Partners"
    categoryDir={printingPartnersDir}
  />
)

const PremiumMediaPartners = () => (
  <CategoryList
    urls={PremiumMediaPartnersUrls}
    category="Premium Media Partners"
    categoryDir={mediaPartnersDir}
  />
)


const PartnersGrid = ({ children }) => (
  <div className={styles.grid}>{children}</div>
)

const Partner = ({ name, url, dir }) => (
  <div className={styles.cell}>
    <a href={url} target="_blank" rel="noopener noreferrer">
      <img
        src={dir(`./${name}.png`)}
        className={styles.responsive_image}
        alt={name}
      />
    </a>
  </div>
)


const GoldPartner = ({ name, url, dir }) => (
  <div className={`${styles.gold_cell}  ${styles.cell}`}>
    <a href={url} target="_blank" rel="noopener noreferrer">
      <img
        src={dir(`./${name}.png`)}
        className={styles.responsive_image}
        alt={name}
      />
    </a>
  </div>
)
const Partners = () => (
  <Layout>
    <div className={styles.container}>
      <GoldPartners />
      <PremiumPartners />
      <SilverPartners />
      <Supporters />
      <AccommodationPartners />
      <TransportationPartners />
      <FoodPartners />
      <PrintingPartners />
      <PremiumMediaPartners />
      <MediaPartners />
    </div>
  </Layout>
)

export default withIntl(Partners)
