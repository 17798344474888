import React from 'react'
import { Paragraph, Heading } from 'grommet'
import { FormattedMessage } from 'react-intl'
import { withIntl, Link } from '../i18n'
import Layout from '../components/layout'
import styles from './voulgaroudis_dimitriadis.module.css'

const speakerPhotos = require.context('../assets/speakers_graphics/', true)

const SpeakerLayout = ({ speaker }) => (
  <Layout>
    <div className={styles.container}>
      <img
        src={speakerPhotos(`./voulgaroudis_dimitriadis.png`)}
        alt={speaker}
        style={{
          maxWidth: '100%',
          margin: '0 auto',
          marginBottom: '2rem',
        }}
      />
      <article style={{ padding: '0 2rem', }}>
        <section className={styles.text_section}>
          <Heading level="3">
          <FormattedMessage id={`voulgaroudis`} /> <FormattedMessage id="speaker_bio" />
          </Heading >
          <Paragraph style={{ maxWidth: '900px', padding: '24px 0', fontSize: '0.9em' }}>
            <FormattedMessage id={`voulgaroudis_bio`} />
          </Paragraph>
        </section>
      </article>
      <article style={{ padding: '0 2rem', }}>
        <section className={styles.text_section}>
          <Heading level="3">
          <FormattedMessage id={`dimitriadis`} /> <FormattedMessage id="speaker_bio" />
          </Heading >
          <Paragraph style={{ maxWidth: '900px', padding: '24px 0', fontSize: '0.9em' }}>
            <FormattedMessage id={`dimitriadis_bio`} />
          </Paragraph>
        </section>
        <section className={styles.text_section}>
          <Heading level="3">
            <FormattedMessage id="speaker_fav_talk" />
          </Heading >
          <Paragraph style={{ maxWidth: '900px', padding: '24px 0', fontSize: '0.9em' }}>
            <FormattedMessage id={`dimitriadis_favorite_talk`} />
          </Paragraph>
        </section>
        <section className={styles.text_section}>
          <Heading level="3">
            <FormattedMessage id="speaker_chasm" />
          </Heading >
          <Paragraph style={{ maxWidth: '900px', padding: '24px 0', fontSize: '0.9em' }}>
            <FormattedMessage id={`dimitriadis_chasm`} />
          </Paragraph>
        </section>
      </article>
    </div>
  </Layout>
)

export default withIntl(SpeakerLayout)
