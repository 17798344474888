import React from 'react'
import SpeakerLayout from '../components/speaker_layout'
import Layout from '../components/layout'
import { withIntl } from '../i18n';


const Mahut = () => (
  <Layout>
    <SpeakerLayout speaker="mahut" />
  </Layout>
)


export default withIntl(Mahut)
