import React from 'react'
import { Box, Heading, Paragraph } from 'grommet'

export default () => (

    <Box
    full
    colorIndex="brand"
    pad="large"
    justify="center"
    align="center">

        <Heading tag="h1">
          <strong>404</strong>
        </Heading>
        <Paragraph>
          You just hit a route that doesn't exist... the sadness.
        </Paragraph>
    </Box>
)
