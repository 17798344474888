import React from 'react'
import Layout from '../components/layout'
import { Facebook, Linkedin } from 'grommet-icons'
import { Heading, Paragraph } from 'grommet'
import { withIntl } from '../i18n'
import { FormattedMessage } from 'react-intl'
import ElliPhoto from '../assets/portraits_team/elli.jpg'
import BarbaraPhoto from '../assets/portraits_team/barbara.jpg'
import CharisPhoto from '../assets/portraits_team/charis.jpg'
import DimitraPhoto from '../assets/portraits_team/dimitra.jpg'
import DimitrisPhoto from '../assets/portraits_team/dimitris.jpg'
import ElenaPhoto from '../assets/portraits_team/elena.jpg'
import EleniPhoto from '../assets/portraits_team/eleni.jpg'
import EvgeniaPhoto from '../assets/portraits_team/evgenia.jpg'
import GwgwPhoto from '../assets/portraits_team/gwgw.jpg'
import KlePhoto from '../assets/portraits_team/kle.jpg'
import KyriakosPhoto from '../assets/portraits_team/kyriakos.jpg'
import LoukiaPhoto from '../assets/portraits_team/loukia.jpg'
import MargaritisPhoto from '../assets/portraits_team/margaritis.jpg'
import PavlosPhoto from '../assets/portraits_team/pavlos.jpg'
import StathisPhoto from '../assets/portraits_team/stathis.jpg'
import ThanasisPhoto from '../assets/portraits_team/thanasis.jpg'
import VaggelisPhoto from '../assets/portraits_team/vaggelis.jpg'
import IoannaPhoto from '../assets/portraits_team/ioanna.jpg'

import styles from './about.module.css'

const TeamMember = ({ name, role, linkedin, face, img }) => {
  return (
    <div className={`${styles.cell} ${styles.speaker}`}>
      <img src={img} className={styles.responsive_image} alt={name} />
      <div className={styles.member_info}>
        <div className={styles.name}>
          <FormattedMessage id={name} />
        </div>
        <div className={styles.role}>{role}</div>
        <div className={styles.social_media_icons}>
          <a href={face} target="blank" className={styles.social_media_icon}>
            <Facebook color="plain" />
          </a>
          <a
            href={linkedin}
            target="blank"
            className={styles.social_media_icon}
          >
            <Linkedin color="plain" />
          </a>
        </div>
      </div>
    </div>
  )
}

const Grid = () => {
  const teamMembers = [
    {
      name: 'pavlosName',
      role: "Speakers' Coach",
      linkedin: 'https://www.linkedin.com/in/pavlos-kyriakidis-005695166/',
      face: 'https://www.facebook.com/pavlos.kiriakidis.3',
      img: PavlosPhoto,
    },
    {
      name: 'dimitraName',
      role: "Speakers' Coach",
      linkedin: 'https://www.linkedin.com/in/dimitra-litaina-0bb323164/',
      face: 'https://www.facebook.com/dimitra.litaina.3',
      img: DimitraPhoto,
    },
    {
      name: 'dimitrisName',
      role: "Speakers' Coach",
      linkedin: 'https://www.linkedin.com/in/dimitris-t-24094aa5/',
      face: 'https://www.facebook.com/kurios.dimitris1',
      img: DimitrisPhoto,
    },
    {
      name: 'klentionaName',
      role: 'Budget & Legal Manager',
      linkedin: 'https://www.linkedin.com/in/kledjona-mollaj-072580169/',
      face: 'https://www.facebook.com/klentiona.mollai',
      img: KlePhoto,
    },
    {
      name: 'margaritisName',
      role: 'Sponsorships Manager',
      linkedin: '#',
      face: 'https://www.facebook.com/marga.riths.3',
      img: MargaritisPhoto,
    },
    {
      name: 'loukiaName',
      role: 'Sponsorships Manager',
      linkedin: 'https://www.linkedin.com/in/loukia-sarasidou-a3ab15156/',
      face: 'https://www.facebook.com/luxake',
      img: LoukiaPhoto,
    },
    {
      name: 'elenaName',
      role: 'Sponsorships Manager',
      linkedin: 'https://www.linkedin.com/in/elena-papachatzopoulou/',
      face: 'https://www.facebook.com/elena.papachatzopoulou',
      img: ElenaPhoto,
    },
    {
      name: 'thanasisName',
      role: 'Production Manager',
      linkedin: 'https://www.linkedin.com/in/athanasios-vinas-221410166/',
      face: 'https://www.facebook.com/thanosV97',
      img: ThanasisPhoto,
    },
    {
      name: 'kyriakosName',
      role: 'Experience Manager',
      linkedin: 'https://www.linkedin.com/in/kyriakos-kalantaridis-a61a58144/',
      face: 'https://www.facebook.com/kyriakos.kalantaridis',
      img: KyriakosPhoto,
    },
    {
      name: 'vaggelisName',
      role: 'Marketing Manager',
      linkedin: 'https://www.linkedin.com/in/evangelos-voulvoutzis-1b43a213a/',
      face: 'https://www.facebook.com/VaggelisVoulvoutzis',
      img: VaggelisPhoto,
    },
    {
      name: 'charisName',
      role: 'Agenda Manager',
      linkedin: 'https://www.linkedin.com/in/charilaos-stoupas-92369892/',
      face: 'https://www.facebook.com/xaristoup?',
      img: CharisPhoto,
    },
    {
      name: 'eleniName',
      role: 'Public Relations Manager',
      linkedin: 'https://www.linkedin.com/in/eleni-philippopoulou-396a5b170/',
      face: 'https://www.facebook.com/elenifilipopoulou',
      img: EleniPhoto,
    },
    {
      name: 'gwgwName',
      role: 'Content Manager',
      linkedin: 'https://www.linkedin.com/in/georgia-peleka-407715114/',
      face: 'https://www.facebook.com/georpele',
      img: GwgwPhoto,
    },
    {
      name: 'stathisName',
      role: 'Web Developer',
      linkedin: 'https://www.linkedin.com/in/stathisdim/',
      face: 'https://www.facebook.com/stathis.dimitriadis.1',
      img: StathisPhoto,
    },
    {
      name: 'evgeniaName',
      role: 'Graphic Designer',
      linkedin: 'https://www.linkedin.com/in/evgenia-politanou-319b11110/',
      face: 'https://www.facebook.com/profile.php?id=100000775608357',
      img: EvgeniaPhoto,
    },
    {
      name: 'barbaraName',
      role: 'Art Director',
      linkedin: 'https://www.linkedin.com/in/barbara-tzama-570b86166/',
      face: 'https://www.facebook.com/barbara.tzama',
      img: BarbaraPhoto,
    },
    {
      name: 'elliName',
      role: 'Curator & Co-organizer',
      linkedin: 'https://www.linkedin.com/in/ellie-zachou-633133145/',
      face: 'https://www.facebook.com/profile.php?id=100008411119273',
      img: ElliPhoto,
    },
    {
      name: 'ioannaName',
      role: 'Organizer',
      linkedin: 'https://www.linkedin.com/in/ioanna-dandelia-40483745/',
      face: 'https://www.facebook.com/joanna.dandelia',
      img: IoannaPhoto,
    },
  ]

  const TeamMemberElements = teamMembers.map((member, index) => (
    <TeamMember key={index} {...member} />
  ))
  return <div className={styles.grid}>{TeamMemberElements}</div>
}

const AboutUs = () => (
  <div className={styles.container}>
    <Heading
      level="3"
      className={styles.about_heading}
      style={{
        textAlign: 'center',
        margin: '2rem auto',
        maxWidth: '960px',
      }}
    >
      <FormattedMessage id="teamIntro" />
    </Heading>
    <Grid />
  </div>
)

const AboutTEDxUoM = () => (
  <div>
    <div className={styles.row}>
      <div className={styles.column}>
        <Heading level="3" className={styles.about_heading}>
          <FormattedMessage id="TEDxUoMprompt" />
        </Heading>
      </div>

      <div className={styles.column}>
        <Paragraph style={{ margin: 0 }} className={styles.paragraph}>
          <FormattedMessage id="aboutTEDxUoM" />
        </Paragraph>
      </div>
    </div>

    <div className={styles.row}>
      <div className={styles.column}>
        <Heading level="3" className={styles.about_heading}>
          <FormattedMessage id="chasmPrompt" />
        </Heading>
      </div>

      <div className={styles.column}>
        <Paragraph style={{ margin: 0 }} className={styles.paragraph}>
          <FormattedMessage id="aboutChasm" />
        </Paragraph>
      </div>
    </div>
  </div>
)

const AboutTed = () => (
  <div style={{ marginTop: '2rem' }}>
    <div className={styles.row}>
      <div className={styles.column}>
        <Heading level="3" className={styles.about_heading}>
          <FormattedMessage id="TEDprompt" />
        </Heading>
      </div>

      <div className={styles.column}>
        <Paragraph style={{ margin: 0 }} className={styles.paragraph}>
          <FormattedMessage id="aboutTED" />
        </Paragraph>
      </div>
    </div>

    <div className={styles.row}>
      <div className={styles.column}>
        <Heading level="3" className={styles.about_heading}>
          <FormattedMessage id="TEDxprompt" />
        </Heading>
      </div>

      <div className={styles.column}>
        <Paragraph style={{ margin: 0 }} className={styles.paragraph}>
          <FormattedMessage id="aboutTEDx" />
        </Paragraph>
      </div>
    </div>
  </div>
)

const About = () => (
  <Layout>
    <AboutTed />
    <AboutTEDxUoM />
    <AboutUs />
  </Layout>
)

export default withIntl(About)
