import React, { Component } from 'react'
import { withIntl, Link } from '../i18n'
import { FormattedMessage } from 'react-intl'
import { Heading } from 'grommet'
import Layout from '../components/layout'
import './speakers.css'

const speakersPlainImages = require.context('../assets/speakers_plain', true)
const speakersStylizedImages = require.context(
  '../assets/speakers_stylized',
  true
)

const Speakers = () => {
  const speakers = [
    'giannisis',
    'nazlidis',
    'legaki',
    'panagiotou',
    'psychouli',
    'tsitsanoudi',
    'ynzo',
    'floudas',
    'skaraki',
    'mahut',
    'levitikos',
    'voulgaroudis_dimitriadis'
  ]

  return (
    <Layout>
      <div style={{ padding: '2rem', maxWidth: '1600px', margin: '0 auto' }}>
        <div className="grid">
          {speakers.map((id, index) => (
            <Speaker key={index} speaker_id={id} />
          ))}
        </div>
      </div>
    </Layout>
  )
}

class Speaker extends Component {
  state = {
    speaker_id: this.props.speaker_id,
  }

  handleMouseEnter = () => {
    const { speaker_id } = this.state
    this.speakerImg.src = speakersStylizedImages(`./${speaker_id}.jpg`)
    this.speakerImg.classList.remove('styled_image')
  }

  handleMouseLeave = () => {
    const { speaker_id } = this.state
    this.speakerImg.src = speakersPlainImages(`./${speaker_id}.jpg`)
    this.speakerImg.classList.add('styled_image')
  }

  render() {
    const { speaker_id } = this.state
    return (
      <div className="cell">
        <Link to={`/${speaker_id}`}>
          <img
            src={speakersPlainImages(`./${speaker_id}.jpg`)}
            className="responsive-image styled_image"
            ref={img => {
              this.speakerImg = img
            }}
            alt={speaker_id}
            onMouseEnter={() => this.handleMouseEnter()}
            onMouseLeave={() => this.handleMouseLeave()}
          />
          <Heading level="2" align="center" className="name">
            <FormattedMessage id={speaker_id} />
          </Heading>
        </Link>
      </div>
    )
  }
}

export default withIntl(Speakers)
