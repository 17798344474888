import React from 'react'
import Layout from '../components/layout'

import { Heading, Box, Paragraph } from 'grommet'

import { FormattedMessage } from 'react-intl'
import { withIntl, Link } from '../i18n'

import tedxLogo from '../assets/chasm_logo.png'
import venueImage from '../assets/venue.jpg'
import speakerCollage from '../assets/speaker_big.jpg'

import red_pattern from '../assets/red_pattern.svg'

import './index.css'

const Tickets = () => (
  <a
    href="http://tickets.tedxuniversityofmacedonia.com"
    target="_blank"
    rel="noopener noreferrer"
    style={{ textDecoration: 'none' }}
  >
    <div
      style={{ marginTop: '7em', fontSize: '1.6em' }}
      className="tickets_container"
    >
      Get your ticket!
    </div>
  </a>
)

const SiteHero = () => (
  <div style={{ padding: '10rem 0 10rem 0' }}>
    <Box
      justify="center"
      align="center"
      textAlign="center"
      alignContent="center"
      className="heading_box"
    >
      <img
        src={tedxLogo}
        alt="TEDx UniversityofMacedonia 2018 logo"
        className="brand-logo"
      />
      <Tickets />
      <Paragraph
        style={{
          marginTop: '2em',
          fontSize: '1.6em',
          color: '#ec1c24',
          lineHeight: '1.375',
          maxWidth: '576px',
        }}
        textAlign="center"
        className="event-info"
      >
        <span style={{ color: '#a91e30' }}>03.11.2018</span> <br />
        <FormattedMessage id="tee" />
        <br />
        <span style={{ letterSpacing: '0.03em' }}>
          <FormattedMessage id="tkm" />
        </span>
      </Paragraph>
      <Paragraph style={{ marginTop: '4em', padding: '0 1rem' }} textAlign="center">
        <FormattedMessage id="aboutChasm" />
      </Paragraph>
    </Box>
  </div>
)

const VenueSection = () => (
  <div
    className="venue-section"
    style={{
      backgroundImage: `url(${red_pattern})`,
    }}
  >
    <div
      className="venue-text"
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      }}
    >
      <Heading tag="h1" style={{ fontSize: '2.67em', fontWeight: '300' }}>
        Venue
      </Heading>
      <Paragraph className="black-paragraph">
        <FormattedMessage id="venue_one" />
      </Paragraph>
      <Paragraph className="black-paragraph">
        <FormattedMessage id="venue_two" />
      </Paragraph>
      <Paragraph className="black-paragraph">
        <FormattedMessage id="venue_three" />
      </Paragraph>
    </div>
    <div
      style={{
        backgroundImage: `url(${venueImage})`,
        backgroundSize: 'cover',
        backgroundPosition: '55% 75%',
      }}
      className="venue-image"
    />
  </div>
)

const Map = () => (
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d636.7586639566154!2d22.9515384658333!3d40.60860138060803!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf2c51894bb5cc5c5!2sTechnical+Chamber+of+Central+Macedonia!5e0!3m2!1sen!2sgr!4v1537545920716"
    width="100%"
    height="600"
    frameBorder="0"
    style={{ border: 0 }}
    allowFullScreen
    title="Venue Map"
  />
)

const Speakers = () => (
  <div
    style={{
      backgroundImage: `url(${speakerCollage})`,
      width: '100%',
      padding: '18rem 0',
      backgroundSize: 'cover',
      backgroundPositionX: '30%',
      backgroundPositionY: '70%',
      filter: 'grayscale(1)',
    }}
  >
    <Box direction="row" justify="center" align="center">
      <Box basis="1/2" align="end" pad="medium" />
      <Box basis="1/2" align="start" pad="medium">
        <Heading margin="none" style={{ color: 'white' }}>
          <Link
            to="/speakers"
            style={{ textDecoration: 'none', color: 'white', fontWeight: 300 }}
            className="speakers_index_link"
          >
            Discover our Speakers
          </Link>
          {/* TODO: Add link to Speakers page */}
        </Heading>
      </Box>
    </Box>
  </div>
)

const Index = () => {
  return (
    <Layout>
      <SiteHero />
      <VenueSection />
      <Map />
      <Speakers />
    </Layout>
  )
}

export default withIntl(Index)
