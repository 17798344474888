import React from 'react'
import SpeakerLayout from '../components/speaker_layout'
import Layout from '../components/layout'
import { withIntl } from '../i18n';


const Floudas = () => (
  <Layout>
    <SpeakerLayout speaker="floudas" />
  </Layout>
)


export default withIntl(Floudas)