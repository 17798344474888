import React from 'react'
import { withIntl } from '../i18n'
import { FormattedMessage } from 'react-intl'
import { Heading, Paragraph } from 'grommet'
import Layout from '../components/layout'
import styles from './performances.module.css'

const performancesDir = require.context('../assets/performances', true)

const Stringless = () => (
  <div style={{ maxWidth: '900px' }}>
    <img
      src={performancesDir(`./stringless.png`)}
      alt="Strignless"
      className={styles.performance_image}
    />
    <div style={{ padding: '1rem' }}>
      <Heading level="2" className={styles.heading}>
        Stringless
      </Heading>
      <Paragraph style={{ maxWidth: '900px', padding: '24px 0' }}>
        <FormattedMessage id="stringless" />
      </Paragraph>
    </div>
  </div>
)

const LesAuRevoir = () => (
  <div style={{ maxWidth: '900px' }}>
    <img
      src={performancesDir(`./les_au_revoir.png`)}
      alt="Les Au Revoir"
      className={styles.performance_image}
    />
    <div style={{ padding: '1rem' }}>
      <Heading level="2" className={styles.heading}>
        Les Au Revoir
      </Heading>
      <Paragraph style={{ maxWidth: '900px', padding: '24px 0' }}>
        <FormattedMessage id="les_au_revoir" />
      </Paragraph>
    </div>
  </div>
)

const WalkingTheDog = () => (
  <div style={{ maxWidth: '900px' }}>
    <img
      src={performancesDir(`./walking.png`)}
      alt="Walking the Dog"
      className={styles.performance_image}
    />
    <div style={{ padding: '1rem' }}>
      <Heading level="2" className={styles.heading}>
        Walking the Dog
      </Heading>
      <Paragraph style={{ maxWidth: '900px', padding: '24px 0 12px 0' }}>
        <div
          className={styles.heading}
          style={{ fontSize: '120%', marginBottom: '0.4em' }}
        >
          <FormattedMessage id={'walking_danae_name'} />
        </div>
        <FormattedMessage id={'walking_danae'} />
      </Paragraph>
      <Paragraph style={{ maxWidth: '900px', padding: '12px 0' }}>
        <div
          className={styles.heading}
          style={{ fontSize: '120%', marginBottom: '0.4em' }}
        >
          <FormattedMessage id={'walking_vaggelis_name'} />
        </div>
        <FormattedMessage id={'walking_vaggelis'} />
      </Paragraph>
      <Paragraph style={{ maxWidth: '900px', padding: '12px 0' }}>
        <div
          className={styles.heading}
          style={{ fontSize: '120%', marginBottom: '0.4em' }}
        >
          Walking the Dog
        </div>
        <FormattedMessage id={'walking'} />
      </Paragraph>
    </div>
  </div>
)

const Performances = () => (
  <Layout>
    <div>
      <Stringless />
      <LesAuRevoir />
      <WalkingTheDog />
    </div>
  </Layout>
)

export default withIntl(Performances)
