import React from 'react'
import Layout from '../components/layout'
import { withIntl } from '../i18n';


const Booklet = () => (
    <Layout>
        <div style={{
            width: '100%',
            height: '100%',
            minHeight: '900px'
        }}>
            <iframe src="https://drive.google.com/file/d/1Ip9VW4VT-ifyI8BGa9S6iWH0yl-4lF1I/preview" width="100%" height="100%" style={{minHeight:"900px", border: 'none'}}></iframe>
        </div>
    </Layout>
)

export default withIntl(Booklet)