import React from 'react'
import SpeakerLayout from '../components/speaker_layout'
import Layout from '../components/layout'
import { withIntl } from '../i18n';


const Skaraki = () => (
  <Layout>
    <SpeakerLayout speaker="skaraki" />
  </Layout>
)


export default withIntl(Skaraki)