import React from 'react'
import { withIntl } from '../i18n'
import { FormattedMessage } from 'react-intl'
import { Heading } from 'grommet'
import Layout from '../components/layout'

import amanatidouImg from '../assets/hosts/amanatidou.jpg'
import kalfasImg from '../assets/hosts/kalfas.jpg'

import styles from './hosts.module.css'

const Hosts = () => (
  <Layout>
    <div className={styles.container}>
      <div className={styles.cell}>
        <img
          src={amanatidouImg}
          className={styles.responsive_image}
          alt="Maria Amanatidou"
        />
        <article>
          <Heading level="2" className={styles.name}>
            <FormattedMessage id="amanatidou" />
          </Heading>
          <p
            style={{ maxWidth: '900px', padding: '24px 0', fontSize: '0.9em' }}
          >
            <FormattedMessage id="amanatidou_bio" />
          </p>
        </article>
      </div>
      <div className={styles.cell}>
        <img
          src={kalfasImg}
          className={styles.responsive_image}
          alt="Efthimis Kalfas"
        />
        <article>
          <Heading level="2" className={styles.name}>
            <FormattedMessage id="kalfas" />
          </Heading>
          <p
            style={{ maxWidth: '900px', padding: '24px 0', fontSize: '0.9em' }}
          >
            <FormattedMessage id="kalfas_bio" />
          </p>
        </article>
      </div>
    </div>
  </Layout>
)

export default withIntl(Hosts)
