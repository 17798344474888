import React from 'react'
import {Paragraph, Heading} from 'grommet'
import { FormattedMessage } from 'react-intl'
import styles from './single_speaker.module.css'

const speakerPhotos = require.context('../assets/speakers_graphics/', true)

const SpeakerLayout = ({ speaker }) => (
  <div className={styles.container}>
    <img
      src={speakerPhotos(`./${speaker}.png`)}
      alt={speaker}
      style={{
        maxWidth: '100%',
        margin: '0 auto',
        marginBottom: '2rem',
      }}
    />
    <article style={{ padding: '0 2rem', }}>
      <section className={styles.text_section}>
        <Heading level="3">
          <FormattedMessage id="speaker_bio" />
        </Heading >
        <Paragraph style={{ maxWidth: '900px', padding: '24px 0', fontSize: '0.9em' }}>
          <FormattedMessage id={`${speaker}_bio`} />
        </Paragraph>
      </section>
      <section className={styles.text_section}>
        <Heading level="3">
          <FormattedMessage id="speaker_fav_talk" />
        </Heading >
        <Paragraph style={{ maxWidth: '900px', padding: '24px 0', fontSize: '0.9em' }}>
          <FormattedMessage id={`${speaker}_favorite_talk`} />
        </Paragraph>
      </section>
      <section className={styles.text_section}>
        <Heading level="3">
          <FormattedMessage id="speaker_chasm" />
        </Heading >
        <Paragraph style={{ maxWidth: '900px', padding: '24px 0', fontSize: '0.9em' }}>
          <FormattedMessage id={`${speaker}_chasm`} />
        </Paragraph>
      </section>
    </article>
  </div>
)

export default (SpeakerLayout)
