// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-i-18-n-redirect-js": () => import("./../../../src/i18n/redirect.js" /* webpackChunkName: "component---src-i-18-n-redirect-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-booklet-js": () => import("./../../../src/pages/booklet.js" /* webpackChunkName: "component---src-pages-booklet-js" */),
  "component---src-pages-floudas-js": () => import("./../../../src/pages/floudas.js" /* webpackChunkName: "component---src-pages-floudas-js" */),
  "component---src-pages-giannisis-js": () => import("./../../../src/pages/giannisis.js" /* webpackChunkName: "component---src-pages-giannisis-js" */),
  "component---src-pages-hosts-js": () => import("./../../../src/pages/hosts.js" /* webpackChunkName: "component---src-pages-hosts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legaki-js": () => import("./../../../src/pages/legaki.js" /* webpackChunkName: "component---src-pages-legaki-js" */),
  "component---src-pages-levitikos-js": () => import("./../../../src/pages/levitikos.js" /* webpackChunkName: "component---src-pages-levitikos-js" */),
  "component---src-pages-mahut-js": () => import("./../../../src/pages/mahut.js" /* webpackChunkName: "component---src-pages-mahut-js" */),
  "component---src-pages-nazlidis-js": () => import("./../../../src/pages/nazlidis.js" /* webpackChunkName: "component---src-pages-nazlidis-js" */),
  "component---src-pages-panagiotou-js": () => import("./../../../src/pages/panagiotou.js" /* webpackChunkName: "component---src-pages-panagiotou-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-performances-js": () => import("./../../../src/pages/performances.js" /* webpackChunkName: "component---src-pages-performances-js" */),
  "component---src-pages-psychouli-js": () => import("./../../../src/pages/psychouli.js" /* webpackChunkName: "component---src-pages-psychouli-js" */),
  "component---src-pages-skaraki-js": () => import("./../../../src/pages/skaraki.js" /* webpackChunkName: "component---src-pages-skaraki-js" */),
  "component---src-pages-speakers-js": () => import("./../../../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-tsitsanoudi-js": () => import("./../../../src/pages/tsitsanoudi.js" /* webpackChunkName: "component---src-pages-tsitsanoudi-js" */),
  "component---src-pages-voulgaroudis-dimitriadis-js": () => import("./../../../src/pages/voulgaroudis_dimitriadis.js" /* webpackChunkName: "component---src-pages-voulgaroudis-dimitriadis-js" */),
  "component---src-pages-ynzo-js": () => import("./../../../src/pages/ynzo.js" /* webpackChunkName: "component---src-pages-ynzo-js" */)
}

